import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Button, CircularProgress, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import { generateScript } from './api';
import styles from './HeadToHeadScreen.module.css';

const HeadToHeadScreen = () => {
  const { state } = useLocation();
  const { playerNames, scriptRating } = state || {};
  const navigate = useNavigate();

  const [currentPlayer, setCurrentPlayer] = useState(0);
  const [cards, setCards] = useState({ characters: [], programs: [], settings: [] });
  const [randomCards, setRandomCards] = useState({ characters: [], programs: [], settings: [] });
  const [selectedCards, setSelectedCards] = useState({ characters: [], programs: [], settings: [] });
  const [script, setScript] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchCards = useCallback(async () => {
    try {
      const collections = ['characters', 'programs', 'settings'];
      const allCards = {};
      for (const coll of collections) {
        const querySnapshot = await getDocs(collection(db, coll));
        allCards[coll] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      }
      setCards(allCards);
      return allCards;
    } catch (error) {
      console.error('Error fetching cards:', error);
      return {};
    }
  }, []);

  const getRandomCards = useCallback((cards, num = 3) => {
    let shuffled = [...cards].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }, []);

  const shuffleCards = useCallback((allCards) => {
    const shuffled = { characters: [], programs: [], settings: [] };
    for (const category in allCards) {
      if (allCards[category] && allCards[category].length > 0) {
        shuffled[category] = getRandomCards(allCards[category], 3);
      } else {
        console.error(`No cards found for category: ${category}`);
      }
    }
    return shuffled;
  }, [getRandomCards]);

  const handleGenerateScript = useCallback(async () => {
    setLoading(true);
    try {
      if (!selectedCards.characters.length || !selectedCards.programs.length || !selectedCards.settings.length) {
        throw new Error('Please select one card from each category');
      }

      const scriptText = await generateScript({
        character: selectedCards.characters[0].name,
        program: selectedCards.programs[0].name,
        setting: selectedCards.settings[0].name,
        isExplicit: scriptRating === 'r-rated'
      });

      if (typeof scriptText !== 'string') {
        throw new Error('Generated script is not a string');
      }

      setScript(scriptText);
    } catch (err) {
      console.error('Script generation error:', err);
      setScript(err.message);
    }
    setLoading(false);
  }, [selectedCards, scriptRating]);

  const toggleSelect = useCallback((card, category) => {
    setSelectedCards(prev => ({
      ...prev,
      [category]: [card] // Only allow one card to be selected per category
    }));
  }, []);

  const handleNextPlayer = useCallback(() => {
    if (currentPlayer < playerNames.length - 1) {
      setSelectedCards({ characters: [], programs: [], settings: [] });
      setScript('');
      setCurrentPlayer(prev => prev + 1);
      setRandomCards(shuffleCards(cards));
    } else {
      navigate('/');
    }
  }, [currentPlayer, playerNames, navigate, cards, shuffleCards]);

  useEffect(() => {
    const initializeGame = async () => {
      try {
        const allCards = await fetchCards();
        if (Object.keys(allCards).length === 0) {
          throw new Error('No cards fetched from the database');
        }
        const shuffledCards = shuffleCards(allCards);
        setRandomCards(shuffledCards);
      } catch (error) {
        console.error('Error initializing game:', error);
        setScript('Failed to initialize game. Please try again. Error: ' + error.message);
      }
      setLoading(false);
    };

    initializeGame();
  }, [fetchCards, shuffleCards]);

  return (
    <div className={styles.headToHeadContainer}>
      <div className={styles.contentWrapper}>
        {loading ? (
          <div className={styles.loadingOverlay}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={styles.playerPrompt}>{playerNames[currentPlayer]}, select your cards</div>

            {['characters', 'programs', 'settings'].map((category) => (
              <div key={category} className={styles.categorySection}>
                <div className={styles.categoryTitle}>{category.charAt(0).toUpperCase() + category.slice(1)}</div>
                <div className={styles.cardsGrid}>
                  {randomCards[category].map((card) => (
                  <div
                  key={card.id}
                  className={`${styles.card} ${selectedCards[category].some(c => c.id === card.id) ? styles.selected : ''}`}
                  onClick={() => toggleSelect(card, category)}
              >
                  <div className={styles.cardMedia} style={{ backgroundImage: `url(${card.image_url})` }}></div>
                  <div className={styles.cardTitle}>{card.name}</div>
              </div>
                  ))}
                </div>
              </div>
            ))}

            <div className={styles.actionButtons}>
              <Button className={styles.generateScript} onClick={handleGenerateScript} disabled={loading}>Generate Script</Button>
              <Button className={styles.nextPlayer} onClick={handleNextPlayer} disabled={loading}>
                {currentPlayer < playerNames.length - 1 ? 'Next Player' : 'Finish'}
              </Button>
            </div>

            {script && (
              <Box className={styles.scriptContainer}>
                <Typography variant="h5" className={styles.subtitle}>Generated Script:</Typography>
                <div className={styles.scriptText} dangerouslySetInnerHTML={{ __html: script }} />
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default HeadToHeadScreen;