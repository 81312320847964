import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MainScreen.module.css';

const MainScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.gtag('config', 'G-WG3T4EFSP3');
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.welcomeBox}>
        <h1 className={styles.title}>Plot Twists!</h1>
        <div className={styles.buttonContainer}>
          <button 
            className={styles.gameModeButton} 
            onClick={() => navigate('/headtohead-setup')}
          >
            Head to Head
          </button>
          <button 
            className={styles.gameModeButton} 
            onClick={() => navigate('/groupplay-setup')}
          >
            Group Play
          </button>
          <button 
            className={`${styles.gameModeButton} ${styles.quickGameButton}`} 
            onClick={() => navigate('/quickgame')}
          >
            Quick Game
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainScreen;