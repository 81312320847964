import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import styles from './SetupScreen.module.css';

const HeadToHeadSetup = () => {
  const [playerCount, setPlayerCount] = useState(2);
  const [playerNames, setPlayerNames] = useState(['', '']);
  const [scriptRating, setScriptRating] = useState('family-friendly');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handlePlayerCountChange = (event) => {
    const count = parseInt(event.target.value);
    setPlayerCount(count);
    setPlayerNames(Array(count).fill(''));
  };

  const handleNameChange = (index, event) => {
    const newNames = [...playerNames];
    newNames[index] = event.target.value;
    setPlayerNames(newNames);
  };

  const handleStartGame = () => {
    const newErrors = {};
    playerNames.forEach((name, index) => {
      if (!name.trim()) {
        newErrors[`player${index + 1}`] = 'Name is required';
      }
    });

    if (Object.keys(newErrors).length === 0) {
      navigate('/headtohead-game', { state: { playerNames, scriptRating } });
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className={styles.setupContainer}>
      <div className={styles.setupBox}>
        <Typography variant="h4" className={styles.setupTitle}>
          Head to Head Setup
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="player-count-label">Number of Players</InputLabel>
          <Select
            labelId="player-count-label"
            value={playerCount}
            onChange={handlePlayerCountChange}
          >
            {[...Array(10)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
            ))}
          </Select>
        </FormControl>
        {playerNames.map((name, index) => (
          <TextField
            key={index}
            fullWidth
            label={`Player ${index + 1} Name`}
            value={name}
            onChange={(e) => handleNameChange(index, e)}
            error={!!errors[`player${index + 1}`]}
            helperText={errors[`player${index + 1}`]}
            margin="normal"
          />
        ))}
        <FormControl fullWidth margin="normal">
          <InputLabel id="script-rating-label">Script Rating</InputLabel>
          <Select
            labelId="script-rating-label"
            value={scriptRating}
            onChange={(e) => setScriptRating(e.target.value)}
          >
            <MenuItem value="family-friendly">Family Friendly</MenuItem>
            <MenuItem value="r-rated">R-Rated</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStartGame}
          fullWidth
          className={styles.setupButton}
        >
          Start Game
        </Button>
      </div>
    </div>
  );
};

export default HeadToHeadSetup;