import axios from 'axios';

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

export const generateScript = async (selectedCards, retries = 3) => {
  console.log('Received data for script generation:', selectedCards);
  const character = selectedCards.character || 'Undefined Character';
  const program = selectedCards.program || 'Undefined Program';
  const setting = selectedCards.setting || 'Undefined Setting';
  const isExplicit = selectedCards.isExplicit || false;

  let promptBase = `Create a ${isExplicit ? 'hilarious and explicit' : 'hilarious, unexpected'} 5 minute TV script featuring ${character} thrown into the world of ${program}. The scene is set with ${character} ${setting}. 

  Key requirements:
  1. The main character must be ${character}.
  2. The world and style should be that of ${program}.
  3. The situation must involve ${setting}.
  4. Blend the essence of ${character} and ${program}, creating outrageous scenarios that subvert expectations.
  5. Ensure the script stays true to the character of ${character} while placing them in this new context.
  6. Structure the text like a TV script with scene descriptions and character dialogues.
  7. Format the script using the following HTML tags:
     - <div class="scene-heading"> for scene headings
     - <div class="action"> for action descriptions
     - <div class="character-name"> for character names
     - <div class="parenthetical"> for parentheticals
     - <div class="dialogue"> for dialogue
     - <div class="transition"> for transitions`;

  const guidelines = isExplicit ? getRatedGuidelines() : getFamilyFriendlyGuidelines();

  try {
    await wait(300); // Wait for 3 seconds before making the request
    const response = await axios.post(OPENAI_API_URL, {
      model: 'gpt-4',
      messages: [
        { role: 'system', content: 'You are a creative script writer. Format the script with HTML tags as specified in the prompt.' },
        { role: 'user', content: promptBase + guidelines }
      ],
      max_tokens: 1000,
      temperature: 0.8,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${openaiApiKey}`,
      },
    });

    return response.data.choices[0].message.content;
  } catch (error) {
    if (error.response && error.response.status === 429 && retries > 0) {
      const delay = Math.pow(2, 4 - retries) * 1000; // Exponential backoff
      console.log(`Rate limited. Retrying in ${delay / 1000} seconds...`);
      await wait(delay);
      return generateScript(selectedCards, retries - 1);
    }
    console.error('Error generating script:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const continueScript = async (currentScript, isExplicit) => {
  const guidelines = isExplicit ? getRatedGuidelines() : getFamilyFriendlyGuidelines();
  const prompt = `Continue the following script for 3-5 more minutes, maintaining the same tone, style, and characters. Use the same HTML formatting as in the original script:\n\n${currentScript}\n\n${guidelines}`;

  try {
    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: 'gpt-4',
        messages: [
          { role: 'system', content: 'You are a creative script writer. Continue the script using the same HTML formatting as the original.' },
          { role: 'user', content: prompt },
        ],
        max_tokens: 1000,
        temperature: 0.8,
      },
      {
        headers: {
          Authorization: `Bearer ${openaiApiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error continuing script:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const getFamilyFriendlyGuidelines = () => `
Key guidelines:
1. Keep the content suitable for all ages, avoiding explicit language or themes.
2. Create absurd situations that highlight the contrast between the character and their new environment.
3. Include witty, quotable dialogue that feels fresh and surprising.
4. Use clever wordplay and situational humor appropriate for family viewing.
5. Speak in the character's true voice - use their vernacular and expressions, but not too much.
6. Use active voice and structure the text like a TV script.

Style notes:
- Think 'alternate universe' where this character exists in an unexpected but family-friendly situation.
- Use irony and meta-humor to comment on the absurdity of the mashup.
- Feel free to break the fourth wall or include surreal elements, keeping it appropriate for all ages.

Remember: The goal is to create a script that's genuinely funny and unexpected while remaining family-friendly.`;

const getRatedGuidelines = () => `
Key guidelines:
1. Create content suitable for mature audiences, allowing for more explicit themes and language.
2. Develop absurd and potentially risqué situations that highlight the contrast between the character and their new environment.
3. Include witty, quotable dialogue that feels fresh, surprising, and edgy.
4. Incorporate subtle nods to real-world controversies or quirks associated with the character, twisting them in clever, unexpected ways.
5. Aim for humor that's clever and edgy, pushing boundaries without being gratuitous.
6. Speak in the character's true voice - use their vernacular and expressions, but not too much.
7. Use active voice and structure the text like a TV script.

Style notes:
- Think 'alternate universe' where this character exists in a completely unexpected and mature situation.
- Use irony and meta-humor to comment on the absurdity of the mashup.
- Don't be afraid to break the fourth wall or include surreal elements.

Remember: The goal is to create a script that's genuinely funny, unexpected, and suited for mature audiences.`;