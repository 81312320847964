import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import styles from './SetupScreen.module.css';

const GroupPlaySetup = () => {
  const navigate = useNavigate();
  const [playerCount, setPlayerCount] = useState(2);
  const [playerNames, setPlayerNames] = useState({});
  const [scriptRating, setScriptRating] = useState('family-friendly');

  const handlePlayerNameChange = (index, name) => {
    setPlayerNames(prev => ({ ...prev, [index]: name }));
  };

  const handleSubmit = () => {
    navigate('/group-play-screen', { state: { players: playerNames, scriptRating } });
  };

  return (
    <div className={styles.setupContainer}>
      <div className={styles.setupBox}>
        <h1 className={styles.setupTitle}>Group Play Setup</h1>
        <FormControl fullWidth margin="normal">
          <InputLabel>Number of Players</InputLabel>
          <Select
            value={playerCount}
            onChange={e => setPlayerCount(e.target.value)}
          >
            {[2, 3, 4, 5, 6].map(num => (
              <MenuItem key={num} value={num}>
                {num}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {Array.from({ length: playerCount }, (_, index) => (
          <TextField
            key={index}
            label={`Player ${index + 1} Name`}
            value={playerNames[index] || ''}
            onChange={e => handlePlayerNameChange(index, e.target.value)}
            fullWidth
            margin="normal"
          />
        ))}
        <FormControl fullWidth margin="normal">
          <InputLabel>Script Rating</InputLabel>
          <Select
            value={scriptRating}
            onChange={e => setScriptRating(e.target.value)}
          >
            <MenuItem value="family-friendly">Family Friendly</MenuItem>
            <MenuItem value="r-rated">R-Rated</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          className={styles.setupButton}
        >
          Start Game
        </Button>
      </div>
    </div>
  );
};

export default GroupPlaySetup;