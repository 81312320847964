import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Button, CircularProgress, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import { generateGroupScript, continueScript } from './groupplayapi';
import styles from './GroupPlayScreen.module.css';

const GroupPlayScreen = () => {
    const { state } = useLocation();
    const { players, scriptRating } = state || {};
    const playerNames = players ? Object.values(players) : [];
    const navigate = useNavigate();

    const [cards, setCards] = useState({ characters: [], programs: [], settings: [] });
    const [randomCards, setRandomCards] = useState({ characters: [], programs: [], settings: [] });
    const [selectedCards, setSelectedCards] = useState({ characters: [], programs: [], settings: [] });
    const [script, setScript] = useState('');
    const [loading, setLoading] = useState(true);
    const [assignedCharacters, setAssignedCharacters] = useState({});

    const fetchCards = useCallback(async () => {
        try {
            const collections = ['characters', 'programs', 'settings'];
            const allCards = {};
            for (const coll of collections) {
                const querySnapshot = await getDocs(collection(db, coll));
                allCards[coll] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            }
            setCards(allCards);
            return allCards;
        } catch (error) {
            console.error('Error fetching cards:', error);
            return {};
        }
    }, []);

    const getRandomCards = (cards, count) => {
        const shuffled = [...cards].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, count);
    };

    const shuffleCards = (allCards) => {
        const shuffled = { characters: [], programs: [], settings: [] };
        for (const category in allCards) {
            if (allCards[category] && allCards[category].length > 0) {
                shuffled[category] = getRandomCards(allCards[category], 3);
            } else {
                console.error(`No cards found for category: ${category}`);
            }
        }
        setRandomCards(shuffled);
        return shuffled;
    };

    const assignCharactersToPlayers = (playerNames, selectedCharacters, selectedPrograms) => {
        const assignments = {};
        const usedCharacters = new Set();

        // First, assign the selected characters
        selectedCharacters.forEach((character, index) => {
            if (index < playerNames.length) {
                assignments[playerNames[index]] = character;
                usedCharacters.add(character);
            }
        });

        // Then, assign characters from the selected programs
        const remainingPlayers = playerNames.filter(player => !assignments[player]);
        const programCharacters = getCharactersFromSelectedPrograms(selectedPrograms).filter(character => !usedCharacters.has(character));

        remainingPlayers.forEach((player, index) => {
            let character = programCharacters[index % programCharacters.length];
            while (usedCharacters.has(character)) {
                index++;
                character = programCharacters[index % programCharacters.length];
            }
            assignments[player] = character;
            usedCharacters.add(character);
        });

        console.log("Assigned characters:", assignments);
        setAssignedCharacters(assignments);
        return assignments;
    };

    const getCharactersFromSelectedPrograms = (selectedPrograms) => {
        const programCharacterMap = {
            '30 Rock': ["Liz Lemon", "Jack Donaghy", "Tracy Jordan", "Jenna Maroney", "Kenneth Parcell"],
            'Jersey Shore': ["Mike 'The Situation' Sorrentino", "Nicole 'Snooki' Polizzi", "Jenni 'JWoww' Farley", "Paul 'Pauly D' DelVecchio", "Vinny Guadagnino"],
            'The Office': ["Michael Scott", "Dwight Schrute", "Jim Halpert", "Pam Beesly", "Ryan Howard", "Andy Bernard", "Angela Martin"],
            'The Sopranos': ["Tony Soprano", "Carmela Soprano", "Christopher Moltisanti", "Paulie Gualtieri", "Silvio Dante", "Meadow Soprano", "A.J. Soprano"],
            'Titanic': ["Jack Dawson", "Rose DeWitt Bukater", "Cal Hockley", "Molly Brown", "Captain Smith", "Thomas Andrews"],
            'Willy Wonka and the Chocolate Factory': ["Willy Wonka", "Charlie Bucket", "Veruca Salt", "Violet Beauregarde", "Augustus Gloop", "Mike Teavee", "Grandpa Joe"],
        };
        let characters = [];
        selectedPrograms.forEach(program => {
            if (programCharacterMap[program]) {
                characters = characters.concat(programCharacterMap[program]);
            }
        });
        return shuffleArray(characters);
    };

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    useEffect(() => {
        const initializeGame = async () => {
            const allCards = await fetchCards();
            if (Object.keys(allCards).length > 0) {
                const shuffledCards = shuffleCards(allCards);
                setRandomCards(shuffledCards);
            }
            setLoading(false);
        };

        initializeGame();
    }, [fetchCards]);

    const toggleSelect = (card, category) => {
        setSelectedCards(prev => ({
            ...prev,
            [category]: [card]
        }));
    };

    const handleContinueScript = async () => {
        setLoading(true);
        try {
            const continuedScript = await continueScript(script, scriptRating === 'r-rated');
            setScript(prevScript => `${prevScript}\n\n${continuedScript}`);
        } catch (err) {
            console.error('Script continuation error:', err.message);
        }
        setLoading(false);
    };

    const handleGenerateScript = async () => {
        setLoading(true);
        try {
            const characterNames = selectedCards.characters.map(card => card.name);
            const programNames = selectedCards.programs.map(card => card.name);
            const settingNames = selectedCards.settings.map(card => card.name);
            const assignments = assignCharactersToPlayers(playerNames, characterNames, selectedCards.programs);
            const characterAssignments = Object.entries(assignments)
                .map(([player, character]) => `${character} (${player})`)
                .join(', ');

            console.log('Character assignments:', characterAssignments);

            let scriptText = await generateGroupScript({
                characters: Object.values(assignments),
                programs: programNames,
                settings: settingNames,
                isExplicit: scriptRating === 'r-rated',
                characterAssignments: characterAssignments,
            });

            console.log('Generated script:', scriptText);

            if (typeof scriptText !== 'string') {
                throw new Error('Generated script is not a string');
            }

            scriptText = formatScript(scriptText);

            setScript(scriptText);
        } catch (err) {
            console.error('Script generation error:', err);
            setScript('Failed to generate script. Please try again. Error: ' + err.message);
        }
        setLoading(false);
    };

    const formatScript = (scriptText) => {
        const lines = scriptText.split('\n');
        let formattedScript = '';
        let isDialogue = false;

        lines.forEach(line => {
            line = line.trim();
            if (line.match(/^<div class="scene-heading">/)) {
                formattedScript += `<h3 class="${styles.sceneHeading}">${line.replace(/<\/?div[^>]*>/g, '')}</h3>`;
            } else if (line.match(/^<div class="action">/)) {
                formattedScript += `<p class="${styles.action}">${line.replace(/<\/?div[^>]*>/g, '')}</p>`;
            } else if (line.match(/^<div class="character-name">/)) {
                formattedScript += `<p class="${styles.characterName}">${line.replace(/<\/?div[^>]*>/g, '')}</p>`;
            } else if (line.match(/^<div class="parenthetical">/)) {
                formattedScript += `<p class="${styles.parenthetical}">${line.replace(/<\/?div[^>]*>/g, '')}</p>`;
            } else if (line.match(/^<div class="dialogue">/)) {
                formattedScript += `<p class="${styles.dialogue}">${line.replace(/<\/?div[^>]*>/g, '')}</p>`;
            } else if (line.match(/^<div class="transition">/)) {
                formattedScript += `<p class="${styles.transition}">${line.replace(/<\/?div[^>]*>/g, '')}</p>`;
            } else if (line !== '') {
                formattedScript += `<p>${line}</p>`;
            } else {
                formattedScript += '<br>';
            }
        });

        return formattedScript;
    };

    return (
        <div className={styles.groupPlayContainer}>
            <div className={styles.contentWrapper}>
                <div className={styles.playerPrompt}>Group, select your cards</div>

                {['characters', 'programs', 'settings'].map((category) => (
                    <div key={category} className={styles.categorySection}>
                        <div className={styles.categoryTitle}>{category.charAt(0).toUpperCase() + category.slice(1)}</div>
                        <div className={styles.cardsGrid}>
                            {randomCards[category].map((card) => (
                                <div
                                    key={card.id}
                                    className={`${styles.card} ${selectedCards[category].some(selectedCard => selectedCard.id === card.id) ? styles.selected : ''}`}
                                    onClick={() => toggleSelect(card, category)}
                                >
                                    <div className={styles.cardMedia} style={{ backgroundImage: `url(${card.image_url})` }}></div>
                                    <div className={styles.cardTitle}>{card.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                <div className={styles.actionButtons}>
                    <Button className={styles.generateScript} onClick={handleGenerateScript} disabled={loading}>Generate Script</Button>
                    <Button className={styles.continueScript} onClick={handleContinueScript} disabled={!script || loading}>Continue Script</Button>
                    <Button className={styles.finish} onClick={() => navigate('/')}>Finish</Button>
                </div>

                {loading && <CircularProgress />}

                {script && (
                    <Box className={styles.scriptContainer}>
                        <div className={styles.scriptText} dangerouslySetInnerHTML={{ __html: script }} />
                    </Box>
                )}
            </div>
        </div>
    );
};

const getRandomCards = (cards, num = 3) => {
    let shuffled = [...cards].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
};

export default GroupPlayScreen;
