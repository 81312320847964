import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainScreen from './MainScreen';
import HeadToHeadSetup from './HeadToHeadSetup';
import GroupPlaySetup from './GroupPlaySetup';
import HeadToHeadScreen from './HeadToHeadScreen';
import GroupPlayScreen from './GroupPlayScreen';
import QuickGameScreen from './QuickGameScreen';
import './variables.module.css';
import './global.module.css';
import styles from './App.module.css';

function App() {
  React.useEffect(() => {
    const initializeAnalytics = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-WG3T4EFSP3');
    };
    initializeAnalytics();
  }, []);

  return (
    <Router>
      <div className={styles.appContainer}>
        <Routes>
          <Route path="/" element={<MainScreen />} />
          <Route path="/headtohead-setup" element={<HeadToHeadSetup />} />
          <Route path="/groupplay-setup" element={<GroupPlaySetup />} />
          <Route path="/headtohead-game" element={<HeadToHeadScreen />} />
          <Route path="/group-play-screen" element={<GroupPlayScreen />} />
          <Route path="/quickgame" element={<QuickGameScreen />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;